/**
 * AUTO-GENERATED from generate-icons script 🤖 - DO NOT EDIT!
 */

import { forwardRef } from 'react'
import Icon from '../icon/icon'

const ArrowheadLeftIconSvg = forwardRef((props, svgRef) => (
	<svg
		width={props.width || (props.height && Math.floor(+props.height * 1)) || 24}
		height={props.height || (props.width && Math.floor(+props.width / 1)) || 24}
		viewBox="0 0 24 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		fontSize="medium"
		ref={svgRef}
		{...props}
	>
		<path
			d="M16.08 4.768L7.92 12l8.16 7.232"
			stroke="currentColor"
			strokeLinecap="round"
			strokeLinejoin="round"
			strokeWidth={1.536}
		/>
	</svg>
))
ArrowheadLeftIconSvg.displayName = 'ArrowheadLeftIconSvg'

const ArrowheadLeftIcon = forwardRef((props, ref) => <Icon component={ArrowheadLeftIconSvg} ref={ref} {...props} />)
ArrowheadLeftIcon.displayName = 'ArrowheadLeftIcon'

ArrowheadLeftIcon.defaultProps = {
	...Icon.defaultProps,
}
ArrowheadLeftIcon.propTypes = {
	...Icon.propTypes,
}

export default ArrowheadLeftIcon
export { ArrowheadLeftIconSvg }
