/**
 * AUTO-GENERATED from generate-icons script 🤖 - DO NOT EDIT!
 */

import { forwardRef } from 'react'
import Icon from '../icon/icon'

const ArrowheadRightIconSvg = forwardRef((props, svgRef) => (
	<svg
		width={props.width || (props.height && Math.floor(+props.height * 1)) || 24}
		height={props.height || (props.width && Math.floor(+props.width / 1)) || 24}
		viewBox="0 0 24 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		fontSize="medium"
		ref={svgRef}
		{...props}
	>
		<path
			d="M7.92 19.232L16.08 12 7.92 4.768"
			stroke="currentColor"
			strokeLinecap="round"
			strokeLinejoin="round"
			strokeWidth={1.536}
		/>
	</svg>
))
ArrowheadRightIconSvg.displayName = 'ArrowheadRightIconSvg'

const ArrowheadRightIcon = forwardRef((props, ref) => <Icon component={ArrowheadRightIconSvg} ref={ref} {...props} />)
ArrowheadRightIcon.displayName = 'ArrowheadRightIcon'

ArrowheadRightIcon.defaultProps = {
	...Icon.defaultProps,
}
ArrowheadRightIcon.propTypes = {
	...Icon.propTypes,
}

export default ArrowheadRightIcon
export { ArrowheadRightIconSvg }
