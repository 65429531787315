import { styled } from '@mui/system'
import Typography from '../../../atoms/typography'
import Button from '../../button'
import BHLink from '../../link'

const shouldForwardProp = (prop) => !['open', 'anchor'].includes(prop)

const NavMenu = styled('nav', { shouldForwardProp })`
	display: flex;
	flex-direction: column;
`

const NavLinkStyled = styled(BHLink)`
	padding: 12px 0px;
	font-size: ${({ theme }) => theme.typography.pxToRem(18)};
	display: flex;
	align-items: center;
	justify-content: space-between;
	cursor: pointer;
	line-height: ${({ theme }) => theme.typography.pxToRem(24)};
	.MuiSvgIcon-root {
		font-size: ${({ theme }) => theme.typography.pxToRem(16)};
	}
`

const NavItem = styled(Typography)`
	&.menu-item-active {
		${NavLinkStyled} {
			color: ${({ theme }) => theme.palette.secondary.main};
		}
	}
`

const NavSubMenu = styled('div', { shouldForwardProp })`
	position: fixed;
	top: 0;
	width: 100%;
	background: ${({ theme }) => theme.palette.white.main};
	height: 100%;
	padding: 0;
	will-change: transition;
	z-index: 2;
	${({ theme }) => theme.scrollbar};
	${({ anchor }) =>
		anchor === 'left'
			? `
					left: -100%;
					transition: left 225ms cubic-bezier(0, 0, 0.2, 1) 0ms;
			  `
			: `
					right: -100%;
					transition: right 225ms cubic-bezier(0, 0, 0.2, 1) 0ms;
			  `}
	&.nav-open {
		${({ anchor }) => (anchor === 'left' ? 'left: 0%;' : 'right: 0%;')}
		&.nav-moved {
			${({ anchor }) => (anchor === 'left' ? 'left: 100%;' : 'right: 100%;')}
		}
	}
`

const ParentNavTitle = styled(BHLink)`
	margin: 0px auto;
	display: block;
	min-height: 20px;
	font-size: ${({ theme }) => theme.typography.pxToRem(24)};
`

const SubNavLinkStyled = styled(BHLink)`
	padding: 12px 0;
	display: flex;
	align-items: center;
	justify-content: space-between;
	cursor: pointer;
	font-size: var(--size-biggest);
	.MuiSvgIcon-root {
		font-size: var(--size-big);
	}
`

const SubContainerNavItem = styled('div')`
	padding: ${({ theme }) => theme.spacing(0, 1)};
	&.empty-column {
		display: none;
	}
`

const SubNavItem = styled(Typography)`
	&.submenu-item-active {
		${SubNavLinkStyled} {
			color: ${({ theme }) => theme.palette.secondary.main};
		}
	}
`

const BackButton = styled(Button)`
	margin: 0;
	border: 0;
	font-family: ${({ theme }) => theme.typography.fontFamily};
	font-weight: normal;
	width: 100%;
	border-bottom: 1px solid var(--color-grey80);
	margin-bottom: ${({ theme }) => theme.spacing(3)};
	&:hover {
		color: var(--color-primary);
		border-bottom: 1px solid var(--color-grey80);
	}

	.MuiButton-startIcon > *:nth-of-type(1) {
		font-size: ${({ theme }) => theme.typography.pxToRem(18)};
	}
	&::after {
		display: none;
	}
`

const SubNavCmsBlock = styled('div')`
	display: none;
	img {
		display: block;
		width: 100%;
	}
`

export {
	BackButton,
	NavMenu,
	NavItem,
	NavLinkStyled,
	NavSubMenu,
	SubNavItem,
	SubNavLinkStyled,
	SubContainerNavItem,
	SubNavCmsBlock,
	ParentNavTitle,
}
