/**
 * AUTO-GENERATED from generate-icons script 🤖 - DO NOT EDIT!
 */

import { forwardRef } from 'react'
import Icon from '../icon/icon'

const SupportIconSvg = forwardRef((props, svgRef) => (
	<svg
		width={props.width || (props.height && Math.floor(+props.height * 1)) || 24}
		height={props.height || (props.width && Math.floor(+props.width / 1)) || 24}
		viewBox="0 0 24 24"
		xmlns="http://www.w3.org/2000/svg"
		fontSize="medium"
		ref={svgRef}
		{...props}
	>
		<path d="M20 11a8 8 0 10-16 0" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
		<path
			d="M2 15.438v-1.877a2 2 0 011.515-1.94l1.74-.435a.6.6 0 01.745.582v5.463a.6.6 0 01-.746.582l-1.74-.434A2 2 0 012 15.438zM22 15.438v-1.877a2 2 0 00-1.515-1.94l-1.74-.435a.6.6 0 00-.745.582v5.463a.6.6 0 00.745.582l1.74-.434A2 2 0 0022 15.438zM20 18v.5a2 2 0 01-2 2h-3.5"
			stroke="currentColor"
		/>
		<path d="M13.5 22h-3a1.5 1.5 0 010-3h3a1.5 1.5 0 010 3z" stroke="currentColor" />
	</svg>
))
SupportIconSvg.displayName = 'SupportIconSvg'

const SupportIcon = forwardRef((props, ref) => <Icon component={SupportIconSvg} ref={ref} {...props} />)
SupportIcon.displayName = 'SupportIcon'

SupportIcon.defaultProps = {
	...Icon.defaultProps,
}
SupportIcon.propTypes = {
	...Icon.propTypes,
}

export default SupportIcon
export { SupportIconSvg }
